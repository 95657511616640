import { constantRoutes, asyncRoutes } from '@/router'

const state = {
    routes: [],
    addRoutes: [],
    currentRoutes: {}
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
    SET_CURRENT_ROUTES: (state, routes) => {
        state.currentRoutes = routes
    }
}

const actions = {
    generateRoutes ({ commit }, data) {
        return new Promise(resolve => {
            const { userType } = data;
            const accessedRouters = asyncRoutes.filter(v => {
                if (hasPermission(userType, v)) {
                    if (v.children && v.children.length != 0) {
                        v.children = v.children.filter(child => {
                            if (hasPermission(userType, child)) {
                                return child
                            }
                            return false;
                        })
                        return v;
                    } else {
                        return v
                    }
                } else {
                    return false
                }
            })
            commit('SET_ROUTES', accessedRouters)
            resolve()
        })
    }
}

//判断是否有权限访问该菜单
function hasPermission (userType, route) {
    if (route) {
        if (route.meta && route.meta.role) {
            let currMenu = getMenu(userType, route.meta.role);
            if (currMenu != null) {
                return true;
            } else {
                if (route.hidden !== undefined && route.hidden === true) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            if (route.hidden != undefined && route.hidden == true) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return true
    }
}

//根据路由名称获取菜单
function getMenu (userType, roles) {
    if (roles) {
        for (let i = 0; i < roles.length; i++) {
            let menusRole = roles[i];
            if (userType == menusRole) {
                return true;
            }
        }
    }
    return null;
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
