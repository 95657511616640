<template>
    <div :class="classObj" class="app-wrapper">
        <topbar />
        <sidebar v-if="!sidebar.hide" class="sidebar-container" />
        <div :class="{ sidebarHide: sidebar.hide }" class="main-container">
            <!-- <div class="fixed-header">
                <navbar />
            </div> -->
            <app-main />
            <Footer />
        </div>
    </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, Topbar, Footer } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
    name: "Layout",
    components: {
        Navbar,
        Sidebar,
        AppMain,
        Topbar,
        Footer,
    },
    mixins: [ResizeMixin],
    computed: {
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        device() {
            return this.$store.state.app.device;
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === "mobile",
            };
        },
    },
};
</script>
