<template>
    <div class="top-nav" id="topNav">
        <div class="log"><img src="https://resources.xbov.cn/img/collectionSystem/logo%403x.png" />鸿星户外广告采集平台</div>
        <el-menu :default-active="activeMenu" active-text-color="#409EFF" mode="horizontal" @select="handleSelect">
            <div v-for="item in routes" :key="item.path" class="nav-item">
                <app-link :to="resolvePath(item)">
                    <el-menu-item v-if="!item.hidden" :index="item.path"
                        ><i :class="item.meta.icon"></i><span>{{ item.meta ? item.meta.title : item.children[0].meta.title }}</span></el-menu-item
                    >
                </app-link>
            </div>
        </el-menu>
        <div class="right-menu">
            <el-dropdown class="avatar-container" trigger="hover">
                <div class="avatar-wrapper">
                    <el-avatar class="user-avatar" :src="userInfo && userInfo.avatarUrl && userInfo.avatarUrl != '' ? userInfo.avatarUrl : ''" icon="el-icon-user-solid"></el-avatar>
                    <span>{{ userInfo && userInfo.userName ? userInfo.userName + "(" + userInfo.userCode + ")" : "" }}</span>
                </div>
                <el-dropdown-menu slot="dropdown" class="user-dropdown">
                    <el-dropdown-item divided>
                        <span style="display: block" @click="personal">个人中心</span>
                    </el-dropdown-item>
                    <el-dropdown-item divided @click.native="logout">
                        <span style="display: block">退出登录</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLink from "./Sidebar/Link";
import { isExternal } from "@/utils/validate";

export default {
    name: "Topbar",
    components: { AppLink },
    data() {
        return {};
    },
    computed: {
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            // 如果是首页，首页高亮
            if (path === "/home") {
                return "/";
            }
            // 如果不是首页，高亮一级菜单
            const activeMenu = "/" + path.split("/")[1];
            return activeMenu;
        },
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        ...mapGetters(["userInfo", "routers"]),
        routes() {
            return this.routers || [];
        },
    },
    mounted() {
        this.initCurrentRoutes();
    },
    methods: {
        async personal() {
            this.$store.dispatch("app/toggleSideBarHide", true);
            this.$router.push(`/personalCenter/personalCenter`);
        },
        // 通过当前路径找到二级菜单对应项，存到store，用来渲染左侧菜单
        initCurrentRoutes() {
            const { path } = this.$route;
            let route = this.routes.find((item) => item.path === "/" + path.split("/")[1]);
            // 如果找不到这个路由，说明是首页
            if (!route) {
                route = this.routes.find((item) => item.path === "/");
            }
            this.$store.commit("permission/SET_CURRENT_ROUTES", route);
            this.setSidebarHide(route);
        },
        // 判断该路由是否只有一个子项或者没有子项，如果是，则在一级菜单添加跳转路由
        isOnlyOneChild(item) {
            if (item.children && item.children.length === 1) {
                return true;
            }
            return false;
        },
        resolvePath(item) {
            // 如果是个完整的url直接返回
            if (isExternal(item.path)) {
                return item.path;
            }
            // 如果是首页，就返回重定向路由
            if (item.path === "/") {
                const path = item.redirect;
                return path;
            }

            // 如果有子项，默认跳转第一个子项路由
            let path = "";
            /**
             * item 路由子项
             * parent 路由父项
             */
            const getDefaultPath = (item, parent) => {
                // 第一次需要父项路由拼接，所以只是第一个传parent
                if (parent) {
                    path += parent.path + "/" + item.path;
                } else {
                    path += "/" + item.path;
                }
                // 如果还有子项，继续递归
                if (item.children) {
                    getDefaultPath(item.children[0]);
                }
            };

            if (item.children) {
                getDefaultPath(item.children[0], item);
                return path;
            }

            return item.path;
        },
        handleSelect(key, keyPath) {
            // 把选中路由的子路由保存store
            const route = this.routes.find((item) => item.path === key);
            this.$store.commit("permission/SET_CURRENT_ROUTES", route);
            this.setSidebarHide(route);
        },
        // 设置侧边栏的显示和隐藏
        setSidebarHide(route) {
            if (!route.children || route.children.length === 0 || route.path == "/" || route.path == "/personalCenter" || route.path == "/statisticalCenter") {
                this.$store.dispatch("app/toggleSideBarHide", true);
            } else {
                this.$store.dispatch("app/toggleSideBarHide", false);
            }
        },
        async logout() {
            await this.$store.dispatch("user/logout");
            this.$store.commit("permission/SET_ROUTES", []);
            this.$router.replace({ path: "/login" });
            location.reload();
        },
    },
};
</script>
