<template>
    <div class="footerBox" id="footerBox">© Copyright 2015 - {{ moment().format("YYYY") }} 长沙鸿星智能科技有限公司 版权所有 | 湘ICP备2020023992号-2号 | 湘公网安备 43019002001389号</div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            moment,
        };
    },
};
</script>
<style lang="less" scoped>
.footerBox {
    height: 50px;
    width: 100%;
    text-align: center;
    line-height: 50px;
}
</style>
