import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: { title: '首页', icon: 'el-icon-s-home' },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: { title: '首页', icon: 'el-icon-s-home' }
      }
    ]
  },
  {
    path: '/personalCenter',
    component: Layout,
    redirect: '/personalCenter',
    hidden: true,
    meta: { title: '个人中心' },
    children: [
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        hidden: true,
        component: () => import('@/views/personalCenter'),
        meta: { title: '个人中心' }
      }
    ]
  },
]

export const asyncRoutes = [
  {
    path: '/acount',
    component: Layout,
    redirect: '/acount',
    meta: { title: '账号管理', icon: 'el-icon-s-custom', role: [1, 3] },
    children: [
      {
        path: 'collectionAcount',
        name: 'CollectionAcount',
        component: () => import('@/views/acountManagement/collectionAcount'),
        meta: { title: '采集账号', icon: 'el-icon-user', role: [1, 3] }
      },
      {
        path: 'agentAcount',
        name: 'AgentAcount',
        component: () => import('@/views/acountManagement/agentAcount'),
        meta: { title: '区代账号', icon: 'el-icon-user', role: [3] }
      }
    ]
  },
  {
    path: '/task',
    component: Layout,
    redirect: '/task',
    meta: { title: '任务管理', icon: 'el-icon-s-flag', role: [1, 2, 3] },
    children: [
      {
        path: 'management',
        name: 'Management',
        component: () => import('@/views/task/list'),
        meta: { title: '任务管理', icon: 'el-icon-tickets', role: [1, 2, 3] }
      },
    ]
  },
  {
    path: '/statisticalReport',
    component: Layout,
    redirect: '/statisticalReport/collectionStatistical',
    name: 'StatisticalReport',
    meta: { title: '采集管理', icon: 'el-icon-s-order', role: [1, 3] },
    children: [
      {
        path: 'collectionStatistical',
        meta: { title: '采集管理', icon: 'el-icon-data-line', role: [1, 3] },
        name: 'CollectionStatistical',
        component: () => import('@/views/statisticalReport/collectionStatistical/index'),
        children: [
          {
            path: 'mediaTypeList',
            name: 'MediaTypeList',
            component: () => import('@/views/statisticalReport/collectionStatistical/mediaTypeList'),
            meta: { title: '媒介列表', role: [1, 3] }
          },
          {
            path: 'mediaList',
            name: 'MediaList',
            component: () => import('@/views/statisticalReport/collectionStatistical/mediaList'),
            meta: { title: '点位列表', role: [1, 3] }
          },
        ]
      },
    ]
  },
  {
    path: '/financial',
    component: Layout,
    redirect: '/financial',
    meta: { title: '财务管理', icon: 'el-icon-s-finance', role: [1, 2, 3] },
    children: [
      {
        path: 'withdrawList',
        name: 'WithdrawList',
        component: () => import('@/views/financial/withdrawList'),
        meta: { title: '提现管理', icon: 'el-icon-wallet', role: [1, 2, 3] }
      },
    ]
  },
  {
    path: '/statisticalCenter',
    component: Layout,
    redirect: '/statisticalCenter',
    meta: { title: '统计中心', icon: 'el-icon-data-analysis', role: [1, 2, 3] },
    children: [
      {
        path: 'statisticalCenter',
        name: 'StatisticalCenter',
        component: () => import('@/views/statisticalCenter/index.vue'),
        meta: { title: '统计中心', icon: 'el-icon-data-analysis', role: [1, 2, 3] }
      }
    ]
  },
  {
    path: '/managementCenter',
    component: Layout,
    redirect: '/managementCenter/certification',
    name: 'ManagementCenter',
    meta: { title: '管理中心', icon: 'el-icon-s-tools', role: [1, 3] },
    children: [
      {
        path: 'certification',
        meta: { title: '权限认证', icon: 'el-icon-postcard', role: [1, 3] },
        name: 'Certification',
        component: () => import('@/views/managementCenter/certification/index'),
        children: [
          {
            path: 'verified',
            name: 'Verified',
            component: () => import('@/views/managementCenter/certification/verified'),
            meta: { title: '实名认证', role: [1] }
          },
          {
            path: 'taskAuthority',
            name: 'TaskAuthority',
            component: () => import('@/views/managementCenter/certification/taskAuthority'),
            meta: { title: '权限管理', role: [1, 3] }
          },
        ]
      },
      {
        path: 'carousel',
        Name: 'Carousel',
        component: () => import('@/views/managementCenter/carousel'),
        meta: { title: '轮播图管理', icon: 'el-icon-picture-outline', role: [3] }
      },
      {
        path: 'appManagement',
        Name: 'AppManagement',
        component: () => import('@/views/managementCenter/appManagement'),
        meta: { title: 'app管理', icon: 'el-icon-mobile-phone', role: [3] }
      }
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
