<template>
    <div>
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu :default-active="activeMenu" :collapse="isCollapse" :collapse-transition="false" background-color="#0f2e56" text-color="#bfcbd9" :unique-opened="false" active-text-color="#409EFF" mode="vertical">
                <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";

export default {
    components: { SidebarItem, Logo },
    computed: {
        ...mapGetters(["sidebar"]),
        routes() {
            return this.$store.state.permission.currentRoutes.children;
        },
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        isCollapse() {
            return !this.sidebar.opened;
        },
    },
};
</script>

<style lang="less" scoped>
.scrollbar-wrapper {
    overflow-x: hidden !important;
}
</style>
