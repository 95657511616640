import Vue from 'vue'
import 'normalize.css/normalize.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/flexible'
import '@/styles/index.less'
import '@/permission'
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
import { Alert, Avatar, Breadcrumb, BreadcrumbItem, Button, Card, Cascader, Checkbox, Col, Collapse, CollapseItem, DatePicker, Descriptions, DescriptionsItem, Dialog, Divider, Drawer, Dropdown, DropdownItem, DropdownMenu, Form, FormItem, Image, Input, Loading, Menu, MenuItem, Message, Option, Pagination, Popconfirm, Popover, Progress, Radio, RadioButton, RadioGroup, Row, Scrollbar, Select, Submenu, Switch, Table, TableColumn, TabPane, Tabs, Tag, TimePicker, Tooltip, Upload, Tree, Autocomplete } from 'element-ui';
import NProgress from 'nprogress'
import * as echarts from 'echarts';//引入echarts

[Button, Menu, MenuItem, Scrollbar, Submenu, Breadcrumb, BreadcrumbItem, Row,
  Col, Form, FormItem, Input, Image, Dropdown, DropdownMenu, DropdownItem, Alert,
  Table, TableColumn, Loading, Select, Option, DatePicker, Tag, Popconfirm, Pagination,
  Dialog, Cascader, Divider, Avatar, Popover, Tabs, TabPane, Card, Upload, Radio, RadioGroup,
  RadioButton, TimePicker, Switch, Drawer, Descriptions, DescriptionsItem, Progress, Collapse,
  CollapseItem, Checkbox, Tooltip, Tree, Autocomplete].forEach(element => {
    Vue.use(element);
  });

NProgress.configure({ showSpinner: false })
Vue.prototype.$message = Message;
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
