<template>
    <section :class="currentRouterName == 'Home' || currentRouterName == 'PersonalCenter' ? 'app-main' : 'app-main whiteBg'">
        <transition name="fade-transform" mode="out-in">
            <router-view :key="key" />
        </transition>
    </section>
</template>

<script>
export default {
    name: "AppMain",
    computed: {
        key() {
            return this.$route.path;
        },
        currentRouterName() {
            return this.$route.name;
        },
    },
};
</script>

<style lang="less" scoped>
.whiteBg {
    background: #fff;
}
.app-main {
    min-height: calc(100vh - 107px);
    width: 100%;
    position: relative;
    overflow: hidden;
}
.fixed-header + .app-main {
    padding-top: 50px;
}
</style>
