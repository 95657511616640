import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, removeToken } from '@/utils/auth'
import router from '@/router'
import { resetRouter } from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 10000
})
service.interceptors.request.use(
  config => {
    config.headers["Content-Type"] = "application/json;charset=UTF-8";

    if (store.getters.token) {
      config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.rescode !== 200 && res.rescode !== 206) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // if (res.resultCode == 204) {
      //   removeToken()
      //   resetRouter()
      //   router.replace({ path: "/login" });
      //   location.reload();
      // }
      return Promise.reject(new Error(res.message || '请求失败！'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: '请求失败！',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
