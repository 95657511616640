import request from '@/utils/request'

/**
 * 登录
 * @param  data 
 * @returns 
 * data:{
    clientType (integer, optional): 客户端类型：0 PC管理端；1 移动端 ,
    imageCode (string, optional): 图片验证码 ,
    loginName (string): 账号 ,
    phoneCode (string, optional): 短信验证码
 } 
 */
export function login (data) {
  return request({
    url: '/login/loginByPhone',
    method: 'post',
    data
  })
}

/**
 * 根据token获取用户信息
 * @param {*} data 
 * @returns 
 */
export function getInfoByToken (data) {
  return request({
    url: '/user/userbasic/queryByToken',
    method: 'get',
    data
  })
}

/**
 * 退出
 */
export function logout () {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

/**
 * 查询账号列表（带分页）
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    province (string, optional): 省份 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    userName (string, optional): 用户姓名 ,
    phone (string, optional): 手机号码 ,
    state (integer, optional): 用户状态：1：已生效；2：禁用；3：已过期 ,
    source (integer, optional): 注册来源：1：自注册；2：直属上级;3: 平台创建，
    userType (integer, optional): 用户类型：1:区代账号；2：采集账号；3：平台账号
 } 
 */
export function getCollecAcountList (data) {
  return request({
    url: '/user/userbasic/queryPageList',
    method: 'post',
    data
  })
}

/**
 * 查询账号列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    province (string, optional): 省份 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    userName (string, optional): 用户姓名 ,
    phone (string, optional): 手机号码 ,
    state (integer, optional): 用户状态：1：已生效；2：禁用；3：已过期 ,
    source (integer, optional): 注册来源：1：自注册；2：直属上级;3: 平台创建，
    userType (integer, optional): 用户类型：1:区代账号；2：采集账号；3：平台账号
 } 
 */
export function getAcountList (data) {
  return request({
    url: '/user/userbasic/queryList',
    method: 'post',
    data
  })
}

/**
 * 平台或区代创建账号
 * @param  data 
 * @returns 
 * data:{
    address (string, optional): 详细地址 ,
    belongToCode (string, optional): 上级或推荐人 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    expirationTime (string, optional): 账号失效期,格式为:yyyy-MM-dd HH:mm:ss ,
    phone (string, optional): 手机号码 ,
    province (string, optional): 省份 ,
    regions (Array[UserTaskRegionVo], optional): 任务允许发布区域 ,
    remark (string, optional): 备注 ,
    smsCode (string, optional): 验证码 ,
    source (integer, optional): 注册来源：1：自注册；2：直属上级 ,
    state (integer, optional): 用户状态：1：已生效；2：禁用；3：已过期 ,
    userName (string, optional): 用户姓名 ,
    userType (integer, optional): 用户类型：1:区代账号；2：采集账号；3：平台账号
 } 
 UserTaskRegionVo {
    addTime (string, optional): 添加时间 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    flowId (integer): 流水号 ,
    level (integer, optional): 等级权限：1：省；2：市；3：区 ,
    province (string, optional): 省份 ,
    userCode (string, optional): 用户编号
  }
 */
export function addByBelongUser (data) {
  return request({
    url: '/user/userbasic/addByBelongUser',
    method: 'post',
    data
  })
}

/**
 * 平台或区代删除账号
 * @param  data 
 * @returns 
 * data:{
    userCode (string): 用户编号 ,
 } 
 */
export function delUser (data) {
  return request({
    url: '/user/userbasic/delete',
    method: 'get',
    params: data
  })
}

/**
 * 平台或区代修改账号
 * @param  data 
 * @returns 
 * data:{
      address (string, optional): 所在地区详细地址 ,
      city (string, optional): 市 ,
      district (string, optional): 区/县 ,
      expirationTime (string, optional): 账号失效期,格式为:yyyy-MM-dd HH:mm:ss ,
      province (string, optional): 省份 ,
      remark (string, optional): 备注 ,
      state (integer, optional): 用户状态：1：已生效；2：禁用；3：已过期 ,
      regions (Array[UserTaskRegionVo], optional): 任务允许发布区域 ,
    } 
    UserTaskRegionVo {
      addTime (string, optional): 添加时间 ,
      city (string, optional): 市 ,
      district (string, optional): 区/县 ,
      flowId (integer): 流水号 ,
      level (integer, optional): 等级权限：1：省；2：市；3：区 ,
      province (string, optional): 省份 ,
      userCode (string, optional): 用户编号
    }
 */
export function updateUser (data) {
  return request({
    url: '/user/userbasic/update',
    method: 'post',
    data
  })
}

/**
 * 平台或区代启用或禁用账号
 * @param  data 
 * @returns 
 * data:{
      state (integer, optional): 用户状态：1：已生效；2：禁用；,
      userCode (string): 用户编号
    } 
 */
export function enableOrDisableUser (data) {
  return request({
    url: '/user/userbasic/enableOrDisableUser',
    method: 'post',
    data
  })
}

/**
 * 查询用户详情
 * @param  data 
 * @returns 
 * data:{
    userCode (string): 用户编号 ,
 } 
 */
export function queryUserInfo (data) {
  return request({
    url: '/user/userbasic/query',
    method: 'get',
    params: data
  })
}

/**
 * 查询申请实名认证审核用户列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    idCardState (integer, optional): 认证状态：0 未认证；1 认证通过 ；2 认证拒绝；3 认证中 ,
    userCode (string, optional): 用户编号 ,
    userName (string, optional): 用户姓名
 } 
 */
export function getUserauthenticationList (data) {
  return request({
    url: '/userauthentication/queryPageList',
    method: 'post',
    data
  })
}

/**
 * 申请实名认证
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    idCardState (integer, optional): 认证状态：0 未认证；1 认证通过 ；2 认证拒绝；3 认证中 ,
    userCode (string, optional): 用户编号 ,
    userName (string, optional): 用户姓名
 } 
 */
export function userauthenticationAdd (data) {
  return request({
    url: '/userauthentication/add',
    method: 'post',
    data
  })
}

/**
 * 查询认证申请详情
 * @param  data 
 * @returns 
 * data:{
    flowId (long): 流水号 ,
 } 
 */
export function userauthenticationQuery (data) {
  return request({
    url: '/userauthentication/query',
    method: 'get',
    params: data
  })
}

/**
 * 实名认证更新
 * @param  data 
 * @returns 
 * data:{
    flowId (integer): 流水号 ,
    idCardState (integer, optional): 认证状态：0 未认证；1 认证通过 ；2 认证拒绝；3 认证中 ,
    reviewRemark (string, optional): 审核备注 ,
 } 
 */
export function userauthenticationUpdate (data) {
  return request({
    url: '/userauthentication/update',
    method: 'post',
    data
  })
}

/**
 * 查询区代账号负责的区域
 * @param  data 
 * @returns 
 * data:{
    flowIdList (Array[integer], optional): 流水号列表 ,
    maxAddTime (string, optional): 最大添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    minAddTime (string, optional): 最小添加时间,格式为:yyyy-MM-dd HH:mm:ss ,
    userCode (string, optional): 用户编号
 } 
 */
export function queryRegionList (data) {
  return request({
    url: '/usertaskregion/queryList',
    method: 'post',
    data
  })
}